import { createRouter, createWebHashHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/:sigMsg',
    name: 'home',
    component: ()=> import('../views/HomeView.vue'),
    props:true
  },
  {
    path: '/vault/:filterQv',
    name: 'vault',
    component: ()=> import('../views/VaultView.vue'),
    props:true
  },
  {
    path: '/liquidity/:qvAddress/:psAddress/:titleBase64/:isPriceTokenA',
    name: 'myLiquidity',
    component: () => import('../views/MyLiquidityView.vue'),
    props:true
  },
  {
    path: '/add/:qvAddress/:poolNameB64',
    name: 'addLiquidity',
    component: () => import('../views/AddLiquidityView.vue'),
    props:true
  },
  {
    path: '/remove',
    name: 'removeLiquidity',
    component: () => import( '../views/RemoveLiquidityView.vue')
  },
  {
    path: '/chart/:qvAddress/:chartTitleBase64',
    name: 'chart',
    component: () => import('../views/DataChartView.vue'),
    props:true
  },
  {
    path: '/divergence',
    name: 'divergence',
    component: () => import('../views/DataDivergenceView.vue'),
    props:true
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
